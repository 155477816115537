import * as React from 'react';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Benefit,
  CRSubtitle,
  SimplePlanDetail,
  DrugCoverageList,
  ListPlanDetail, TooltipedTitle
} from '@coverright/ui/marketplaces';
import { Tooltip } from '@coverright/ui/inputs';
import { SxProps } from '@mui/material';
import {
  getCompanyLogo, GetDefaultPlansYear,
  GetPlansYear,
  mapDeliveryType,
  tooltips
} from '@coverright/utils';
import { getInitialTooltipByTier } from '@coverright/utils';
import {
  PdpPlanWithTiers,
  useCompareYearToYearByClientId,
  useSaveCompareYearToYear,
} from '@coverright/data-access/medicare';
import {
  useDrugsWithPackage,
  useFindPharmacyByNpi,
} from '@coverright/data-access/medicare';
import {
  ComparablePlanType,
  DrugInfoWithPack,
  MedigapPreferredDrugOutput,
  PharmacyLocationDistanceOutput,
  PlanYear,
} from '@coverright/data-access/types/medicare';
import { AdminContext, CompareOfferContext } from '@coverright/shared/contexts';
import * as Scroll from 'react-scroll';
import { useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from 'notistack';
import * as _ from 'lodash';
import { PdpDrugCostBreakdownModal } from '@coverright/widgets';

const scroll: any = Scroll.scroller;
const Element: any = Scroll.Element;

type PdpPlanCardProps = {
  planYear?: PlanYear;
  plan: PdpPlanWithTiers;
  zip: string;
  clientId?: string;
  countyName: string;
  lowestCost?: boolean;
  onSelectClick?: () => void;
  onPharmacyClick?: () => void;
  onAddDrugsClick?: () => void;
  selectButtonComponent?: JSX.Element;
  preferredDrugs: MedigapPreferredDrugOutput[];
  preferredPharmacies: string[];
};

export function PdpPlanCard({ plan, ...props }: PdpPlanCardProps) {
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { preferredDrugs, preferredPharmacies } = props;
  const [pharmacies, setPharmacies] = React.useState<
    PharmacyLocationDistanceOutput[]
  >([]);
  const {
    plans: compareOfferPlans,
    togglePlan: toggleCompareOffer,
    loading: compareOfferLoading,
  } = React.useContext(CompareOfferContext);
  const adminContext = React.useContext(AdminContext);
  const location = useLocation();

  const [getY2Y] = useCompareYearToYearByClientId();
  const [saveY2Y] = useSaveCompareYearToYear();

  const [getPharmacy] = useFindPharmacyByNpi({
    onCompleted: (data) => {
      if (data?.findPharmacyByNpi) {
        setPharmacies([data.findPharmacyByNpi]);
      }
    },
  });

  React.useEffect(() => {
    if (location.hash === '#' + plan.bidId) {
      scroll.scrollTo(plan.bidId, {});
    }
  }, [location.hash, plan]);

  React.useEffect(() => {
    if (open && preferredPharmacies?.length) {
      getPharmacy({ variables: { npi: preferredPharmacies[0] } });
    }
  }, [preferredPharmacies, open]);

  const AdminActions = () => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const addToY2Y = () => {
      if (adminContext?.clientId) {
        getY2Y({ variables: { clientId: adminContext?.clientId } }).then(
          (res) => {
            const y2y = res.data?.compareYearToYearByClientId;
            return saveY2Y({
              variables: {
                input: {
                  ...({
                    ..._.omit(y2y, 'isLocked'),
                    createdAt: undefined,
                    updatedAt: undefined,
                  } || {}),
                  suggestedPdpPlanId: plan.bidId,
                  currentYear: GetDefaultPlansYear(),
                  zip: props.zip,
                  county: props.countyName,
                  clientId: adminContext?.clientId,
                },
              },
            });
          }
        );
      }
    };

    return (
      <>
        <IconButton
          sx={{ position: 'absolute', right: '5px', top: '5px' }}
          size="small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              toggleCompareOffer({
                bidId: plan.bidId,
                type: ComparablePlanType.Pdp,
                pdpPrimary: !compareOfferPlans.some((p) => p.pdpPrimary),
                county: props.countyName,
                zip: props.zip,
                planYear: plan.planYear,
              });
              handleClose();
            }}
          >
            {compareOfferPlans.some(
              (o) =>
                o.bidId === plan.bidId &&
                (!o.planYear || o.planYear === plan.planYear)
            )
              ? 'Remove from\n'
              : 'Add to'}{' '}
            compare offer
          </MenuItem>
          {plan.planYear === PlanYear.Year2023 && (
            <MenuItem
              onClick={() => {
                addToY2Y();
                handleClose();
              }}
            >
              Add to Y2Y
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <Element name={plan.bidId}>
      <PdpDrugCostBreakdownModal
        bidId={plan.bidId}
        zip={props.zip}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        countyName={props.countyName}
        planYear={plan.planYear || GetDefaultPlansYear()}
        clientId={props.clientId}
      />
      <Card data-test={'pdp_plan_card'}>
        {plan.nonCommissionable && <Box sx={{
          padding: '5px 0 7px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#950101',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottom: '6px solid white'
        }}>
          <TooltipedTitle
            title={'Non commissionable'}
            labelSx={{ color: 'white', fontWeight: 700 }}
            tooltip={''}
          />
        </Box>}
        {props.lowestCost && (
          <Box
            sx={{
              display: 'flex',
              p: { xs: '8px 16px 8px 20px', md: '8px 16px 8px 26px' },
              justifyContent: 'space-between',
              flex: 1,
              alignItems: 'center',
              minHeight: '22px',
              backgroundColor: '#0A2E86',
            }}
          >
            <Typography sx={{ color: 'white' }} className={'bold fs-16 lh-20'}>
              Your recommended Prescription Drug Plan
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ color: 'white', mr: '12px' }}
                className={'bold fs-12'}
                align={'right'}
              >
                Lowest Cost
              </Typography>
              <img src={'/assets/img/ribbon.svg'} className={'w-16 h-24'} />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            p: { xs: 2, md: '12px 10px 0 26px' },
            display: { xs: 'block', sm: 'none' }
          }}
          className={'mb-5'}
        >
          <Typography
            sx={{
              mr: 1,
              fontSize: { xs: 24, md: 28 },
              lineHeight: { xs: '30px', md: '35px' }
            }}
            className={'bold'}
          >
            {plan.planName} Plan
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: plan.insulinSavings
                ? 'space-between'
                : 'flex-end'
            }}
          >
            {!!plan.insulinSavings && (
              <Benefit value={true} label={'Insulin savings'} />
            )}
            <PlanLogo
              plan={plan}
              sx={{ mt: '4px!important', backgroundPosition: 'right' }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: { xs: 2, md: '12px 10px 0 26px' },
            display: 'flex',
            gap: '20px',
            flexDirection: { xs: 'column-reverse', md: 'row' }
          }}
        >
          <Box className={'flex-column'}>
            <Box
              sx={{ display: { xs: 'none', sm: 'block' } }}
              className={'flex flex-space-between mb-5'}
            >
              <Typography
                sx={{
                  mr: 1,
                  fontSize: { xs: 24, md: 28 },
                  lineHeight: { xs: '30px', md: '35px' }
                }}
                className={'bold'}
              >
                {plan.planName} Plan
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <PlanLogo
                  plan={plan}
                  sx={{ mt: '4px!important', backgroundPosition: 'left' }}
                />
                {!!plan.insulinSavings && (
                  <Benefit value={true} label={'Insulin savings'} />
                )}
              </Box>
            </Box>
            <div className={'flex flex-justify-end'}>
              {/*<PlanSubtitle plan={plan}/>*/}
            </div>
            <Box
              sx={{
                display: 'flex',
                mt: 2,
                flexDirection: { xs: 'column', sm: 'row' }
              }}
            >
              <Tooltip placement={'top'} arrow title={''}>
                <div className={'flex-column'}>
                  <Typography className={'bold fs-14 mb-8 lh-14'}>
                    Monthly Premium:
                  </Typography>
                  {/*<div className={'fs-12 lh-15 mb-10'}><b>{plan.drugsTiers30Preferred?.initialCoverageDrugTiers.find(t => t.tier === DrugTierOutput.PreferredGeneric)?.coverage}</b> for preferred generics</div>*/}
                  <div className={'fs-12 lh-15 mb-10'}>
                    <b>{plan.monthlyPremium}</b> per month
                  </div>
                </div>
              </Tooltip>
              <Box
                sx={{
                  borderLeft: { sm: '1px solid #E8ECED' },
                  borderTop: { xs: '1px solid #E8ECED', sm: 'none' },
                  mb: { xs: '20px', sm: 0 },
                  mx: { sm: '20px' }
                }}
              />
              <div className={'flex-column'}>
                <Typography className={'bold fs-14 mb-8 lh-14'}>
                  Annual Deductible:
                </Typography>
                <div className={'fs-12 lh-15 mb-10'}>
                  <b>{plan.drugDetails?.mrxAltDedAmount}</b> p.a.
                </div>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              background: 'rgba(2, 144, 148, 0.05)',
              borderRadius: '10px',
              p: '8px 15px 14px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minWidth: 190,
              position: 'relative'
            }}
          >
            {!!adminContext && <AdminActions />}
            <Typography className={`fs-28 lh-35 bold mb-8`}>
              {plan.monthlyCost || 'N/A'}
            </Typography>
            <Tooltip placement={'top'} arrow title={tooltips.pdpTotalCost}>
              <Box display={'flex'} alignItems={'flex-start'}>
                <Typography className={`fs-14 lh-17 mr-15 bold`}>
                  Average monthly cost
                </Typography>
                <img
                  className={'w-16 h-16'}
                  src={'/assets/img/info-green.svg'}
                />
              </Box>
            </Tooltip>
            {!!preferredDrugs?.length && (
              <Button
                color={'secondary'}
                size={'small'}
                sx={{ px: '15px!important', mt: '12px' }}
                onClick={() => setModalOpen(true)}
                variant={'outlined'}
              >
                Drug cost breakdown
              </Button>
            )}

            {props.selectButtonComponent ? (
              <>{props.selectButtonComponent}</>
            ) : (
              <Button
                sx={{ px: '15px!important', mt: 1 }}
                size={'small'}
                fullWidth
                onClick={props.onSelectClick}
                variant={'contained'}
              >
                Select this plan
              </Button>
            )}
          </Box>
        </Box>
        <div className={'mh-20'}>
          <Divider sx={{ my: 1 }} />
        </div>
        <div
          className={'pointer flex-column flex-align-center'}
          onClick={() => setOpen((prev) => !prev)}
        >
          <Box
            sx={{
              pb: '18px',
              pt: 1,
              '& img': {
                marginLeft: 5,
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: 'all 0.3s'
              }
            }}
            className={'flex'}
          >
            <Typography className={'fs-12 lh-12 '}>
              {open ? 'Hide' : 'Show'} additional details
            </Typography>
            <img src={'/assets/img/green-arrow-small.svg'} />
          </Box>
        </div>
        <Collapse in={open}>
          <>
            <CRSubtitle
              sx={{
                mx: 1,
                width: 'calc(100% - 16px)',
                borderRadius: '10px',
                p: '7px 16px'
              }}
              titleClassName={'fs-20'}
              title={'Additional Details'}
              icon={'/assets/img/medical-record-1.svg'}
            />
            <Box p={'18px 26px 16px'}>
              <SimplePlanDetail
                title={'Plan Type'}
                tooltip={''}
                content={`Prescription Drug Plan (PDP)`}
              />
              <SimplePlanDetail
                title={'Your Prescriptions'}
                tooltip={''}
                content={
                  <div>
                    <DrugCoverageList
                      labelClassName={'text-primary mb-8'}
                      hideTitle
                      coverage={plan.drugsCoverage}
                    />
                    <div className={`h-8`} />
                    <Button
                      className={'w-140'}
                      variant={'outlined'}
                      size={'small'}
                      sx={{ px: '24px!important' }}
                      onClick={props.onAddDrugsClick}
                    >
                      {plan?.drugsCoverage?.length ? 'Edit' : 'Add'} drugs
                    </Button>
                  </div>
                }
              />
              <SimplePlanDetail
                title={'Your Pharmacy'}
                tooltip={''}
                content={
                  <div>
                    {plan?.quoteDrugsTiers?.pharmacyName && (
                      <Box display={'flex'} flexGrow={2} mb={'15px'}>
                        <Benefit
                          label={
                            plan?.quoteDrugsTiers?.pharmacyName +
                            ` (${mapDeliveryType(
                              plan?.quoteDrugsTiers?.deliveryType
                            )})`
                          }
                          value={true}
                          labelClass={'regular color-primary'}
                        />
                      </Box>
                    )}
                    <Button
                      variant={'outlined'}
                      className={'w-140'}
                      size={'small'}
                      onClick={props.onPharmacyClick}
                    >
                      {plan?.quoteDrugsTiers?.pharmacyName ? 'Change' : 'Add'}{' '}
                      Pharmacy
                    </Button>
                  </div>
                }
              />
              <ListPlanDetail
                title={'Prescription Drug Deductible'}
                tooltip={''}
                titleClassName={'fs-16'}
                content={[
                  plan?.drugDetails?.mrxAltDedAmount || '',
                  plan?.drugDetails?.mrxAltNoDedTier || ''
                ]}
              />
              <SimplePlanDetail
                title={'Initial Coverage Limit'}
                tooltip={''}
                content={plan.drugDetails?.initialCoverageLimit}
              />
              {plan.planYear === PlanYear.Year2024 && <SimplePlanDetail
                title={'Coverage Gap (\'Donut Hole\') Limit'}
                tooltip={''}
                content={plan.drugDetails?.catastrophicThreshold}
              />}
              <SimplePlanDetail
                hideDivider
                title={'Initial coverage breakdown'}
                tooltip={''}
                content={''}
              />
              <div className={'ph-40 mt-16'}>
                <Typography className={'bold fs-12 mb-16'}>
                  Preferred retail pharmacy cost sharing for 30 day supply
                </Typography>
                {plan.drugsTiers30Preferred?.initialCoverageDrugTiers.map(
                  (item: any) => (
                    <SimplePlanDetail
                      key={item.tierName}
                      title={item.tierName}
                      titleClassName={'fs-12'}
                      imgClassName={'w-15 h-15'}
                      tooltip={getInitialTooltipByTier(item.tier)}
                      hideDivider
                      content={item.coverage}
                      thin
                    />
                  )
                )}
              </div>
              <Divider sx={{ my: 3 }} />
              <Box display={'flex'} justifyContent={'flex-end'}>
                {props.selectButtonComponent ? (
                  <>{props.selectButtonComponent}</>
                ) : (
                  <Button
                    className={'w-190'}
                    size={'small'}
                    onClick={props.onSelectClick}
                    variant={'contained'}
                  >
                    Select this plan
                  </Button>
                )}
              </Box>
            </Box>
          </>
        </Collapse>
      </Card>
    </Element>
  );
}

const PlanLogo = React.memo(
  ({ plan, sx }: { plan: PdpPlanWithTiers } & { sx?: SxProps }) => {
    const Logo = React.useMemo(() => {
      const url = getCompanyLogo(plan.parentOrgName, plan.orgName);
      if (url) {
        return (
          <Box
            sx={{
              height: 27,
              width: 96,
              minWidth: 96,
              marginTop: 0,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'left',
              ...sx,
            }}
            style={{ backgroundImage: `url("${url}")` }}
          />
        );
      } else {
        return null;
      }
    }, [plan.parentOrgName, plan.orgName]);

    return Logo;
  }
);
